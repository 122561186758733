import axios from 'axios';
import store from '@/store/index';
import appConfigs from '@/config';

const instance = axios.create();
instance.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json';
  config.headers.Accept = 'application/json';

  config.baseURL = appConfigs.get('VUE_APP_ACL_MODULE_URL') || '';
  config.headers.Authorization = store.getters['User/aclAuthToken'];

  // config.baseURL = 'https://acl.staging.swordhealth.com';
  // eslint-disable-next-line max-len
  // config.headers.Authorization = '';
  return config;
});

export default instance;
